import React from 'react'

export default function Footer() {
  return (
    <footer
      style={{
        backgroundColor: '#F1F3F4',
        padding: '15px',
        textAlign: 'center',
        marginTop: '20px',
      }}>
      © Created and Presented with 💙 by <a href='#fanilo'>Fanilo</a>
    </footer>
  )
}
